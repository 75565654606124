import { getQuizes } from '../services/gameService'

export const QUIZES_LOADING = 'QUIZES_LOADING'
export const QUIZES_LOADED = 'QUIZES_LOADED'

export const fetchQuizes = () => dispatch => {
  dispatch({
    type: QUIZES_LOADING
  })
  return getQuizes().then((result) => {
    dispatch({
      type: QUIZES_LOADED,
      payload: result
    })
  }).catch((e) => {
    console.log(e)
  })
}
