import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import * as singlePlayerActions from '../../actions/singlePlayer'
import { getSinglePlayerGame } from '../../reducers/singlePlayer'
import { getQuizes } from '../../reducers/quiz'
import Question from '../../components/Question/Question'
import Result from './Result'
import { firebaseAnalytics } from '../../firebase'
import styles from './Single.module.scss'


class Single extends Component {
  constructor(props) {
    super(props)

    this.answer = this.answer.bind(this)
    this.nextQuestion = this.nextQuestion.bind(this)
    this.goHome = this.goHome.bind(this)

    const { match: { params } } = this.props
    let quiz = this.props.quiz.get('quizes').filter(quiz => quiz.guid === params.id)
    if (quiz.length < 1) {
      quiz = null
    } else {
      quiz = quiz[0]
    }

    this.state = {
      answerId: undefined,
      currentQuestion: 0,
      goToHome: false,
      homePath: '/',
      quiz
    }
  }

  componentDidMount() {
    console.log('SINGLE GAME MOUNTED')
    if (!this.props.singlePlayerGame.get('isLoading') && !this.props.singlePlayerGame.get('isLoaded')) {
      const { match: { params } } = this.props
      this.setState({ homePath: `/quiz/${params.id}#single`, goToHome: true })
    }

    firebaseAnalytics.logEvent('page_view', {
      page_path: this.props.location.pathname
    })
  }

  componentWillUnmount() {
    console.log('SINGLE GAME UNMOUNTED')
  }

  nextQuestion() {
    this.setState({ answerId: undefined, currentQuestion: this.state.currentQuestion + 1})
  }

  answer(optionId) {
    this.props.answerSinglePlayer(this.state.currentQuestion, optionId)
    if (this.state.answerId === undefined) {
      this.setState({ answerId: optionId })
    }
    console.log(this.props.singlePlayerGame.get('answers'))
  }

  goHome() {
    this.props.setNotInProgress()
    this.setState({ goToHome: true })
  }

  render() {
    if (!this.state.quiz) {
      return <Redirect to='/'></Redirect>
    }

    return (
      (!this.state.goToHome) ?
        <div>
          {(!this.props.singlePlayerGame.get('inProgress')) ?
            <div className={styles['lds-ripple']}><div></div><div></div></div>
            :
            this.props.singlePlayerGame.get('questions').length <= this.state.currentQuestion ?
              <Result
                questions={this.props.singlePlayerGame.get('questions')}
                goHome={this.goHome}
              />
              :
              <Question
                question={this.props.singlePlayerGame.get('questions')[this.state.currentQuestion]}
                totalQuestions={this.props.singlePlayerGame.get('questions').length}
                currentQuestion={this.state.currentQuestion + 1}
                answer={this.answer}
                hideTimer={true}
                nextQuestion={this.nextQuestion}
              />
          }
        </div>
        :
        <Redirect to={this.state.homePath}></Redirect>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    singlePlayerGame: getSinglePlayerGame(state),
    quiz: getQuizes(state)
  }
}

export default connect(mapStateToProps, {...singlePlayerActions})(Single)