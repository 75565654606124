import { gameRef } from '../firebase'
import { getAvailableGame } from '../services/gameService'

export const FIND_GAME = 'FIND_GAME'
export const UPDATE_GAME = 'UPDATE_GAME'
export const DESTROY_GAME = 'DESTROY_GAME'

export const findGame = (user, quiz) => dispatch => {
  dispatch({
    type: FIND_GAME
  })
  getAvailableGame(user, quiz).then((game) => {
    const unsubscribe = gameRef.doc(game.id)
      .onSnapshot((doc) => {
        console.log('GAME UPDATED')

        const data = doc.data()
        data.uid = doc.id
        dispatch({
          type: UPDATE_GAME,
          payload: data
        })

        if (data.isCompleted === true) unsubscribe()
      })
  }).catch((err) => {
    console.log(err)
  })
}

export const destroyGame = () => dispatch => {
  dispatch({
    type: DESTROY_GAME
  })
}
