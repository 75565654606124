import React, { Component } from 'react'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import * as userActions from './actions/user'
import * as quizActions from './actions/quiz'
import { getUser } from './reducers/user'
import { getQuizes } from './reducers/quiz'
import './styles/Global.module.scss'
import { firebaseAnalytics } from './firebase'
import styles from './styles/Global.module.scss'

class App extends Component {
  componentDidMount() {
    this.props.userIsLoading()
    this.props.fetchQuizes()

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        let displayName = user.displayName
        const photoURL = user.photoURL
        const uid = user.uid

        if (user.isAnonymous) {
          displayName = `Guest${Math.floor((Math.random() * 9999) + 99)}`
          firebaseAnalytics.logEvent('login', {
            method: 'Anonymous'
          })
        } else {
          firebaseAnalytics.logEvent('login', {
            method: 'Facebook'
          })
        }

        firebaseAnalytics.setUserId(uid)
        firebaseAnalytics.setUserProperties({ is_anonymous: user.isAnonymous })

        this.props.userAuth(uid, displayName, photoURL)
        console.log('USER IS SIGNED IN')
      } else {
        this.props.userIsLoaded()
        console.log('USER IS SIGNED OUTT')
      }
    }, function (error) {
      console.log(error)
    })
  }

  render() {
    if (this.props.quiz.get('isLoading') && !this.props.quiz.get('isLoaded')) {
      // quizes loading
      return (<div className={styles['lds-ripple']}><div></div><div></div></div>)
    } else if (!this.props.quiz.get('isLoading') && this.props.quiz.get('isLoaded') && this.props.quiz.get('quizes')) {
      // quizes loaded
      return (
        this.props.children
      )
    } else {
      // temporary transition state
      return (<div className={styles['lds-ripple']}><div></div><div></div></div>)
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: getUser(state),
    quiz: getQuizes(state)
  }
}

export default connect(mapStateToProps, { ...userActions, ...quizActions })(App)
