import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import reduxThunk from 'redux-thunk'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import rootReducer from './reducers'
import * as serviceWorker from './serviceWorker'
import Routes from './routes'
import { composeWithDevTools } from 'redux-devtools-extension'

const version = require('../package.json').version
Sentry.init({
  dsn: 'https://15aa43ee78d943069b5fc76563f43f8d@sentry.io/2595269',
  release: version
})

const devTools =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(reduxThunk)
    : composeWithDevTools(applyMiddleware(reduxThunk))

export const store = createStore(rootReducer, {}, devTools)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
serviceWorker.register()