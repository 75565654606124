import { getQuestions } from '../services/gameService'
export const SINLGE_GAME_IS_LOADING = 'SINLGE_GAME_IS_LOADING'
export const SINLGE_GAME_IS_LOADED = 'SINLGE_GAME_IS_LOADED'
export const SINLGE_GAME_ANSWER = 'SINLGE_GAME_ANSWER'
export const SINLGE_GAME_ENDED = 'SINLGE_GAME_ENDED'

export const fetchQuestions = (quiz, noOfQuestions) => dispatch => {
  dispatch({
    type: SINLGE_GAME_IS_LOADING
  })
  return getQuestions(quiz, noOfQuestions).then((result) => {
    dispatch({
      type: SINLGE_GAME_IS_LOADED,
      payload: result
    })
  }).catch((e) => {
    console.log(e)
  })
}

export const answerSinglePlayer = (questionIdx, answerId) => dispatch => {
  dispatch({
    type: SINLGE_GAME_ANSWER,
    payload: { questionIdx, answerId }
  })
}

export const setNotInProgress = () => dispatch => {
  dispatch({
    type: SINLGE_GAME_ENDED
  })
}
