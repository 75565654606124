import { combineReducers } from 'redux'
import game from './game'
import user from './user'
import singlePlayer from './singlePlayer'
import quiz from './quiz'

export default combineReducers({
  game,
  user,
  singlePlayer,
  quiz
})