import React from 'react'
import { Route, Switch } from 'react-router-dom'
import App from './App'
import Quiz from './pages/Quiz'
import Homepage from './pages/Homepage'
import Lobby from './pages/Lobby'
import Game from './pages/Game'
import Single from './pages/Single'
import NotFound from './pages/NotFound'
import Content from './pages/Content'

const Routes = () => (
  <App>
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/quiz/:id" component={Quiz} />
      <Route exact path="/quiz/:id/lobby" component={Lobby} />
      <Route exact path="/quiz/:id/single" component={Single} />
      <Route exact path="/game/:id" component={Game} />
      <Route exact path="/content/:id" component={Content} />

      <Route component={NotFound} />
    </Switch>
  </App>)

export default Routes