import React from 'react'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import styles from './Homepage.module.scss'
import { firebaseAnalytics } from '../../firebase'
import { getBackgroundCSS } from '../../helpers/browserSupport'

class GameCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      css: null
    }
  }

  componentDidMount() {
    getBackgroundCSS(this.props.image).then((css) => {
      this.setState({ css })
    })
  }

  render() {
    return (
      <Link to={`/quiz/${this.props.guid}`} onClick={() => {
        firebaseAnalytics.logEvent('select_content', {
          content_type: 'quiz',
          content_id: this.props.guid
        })
      }}>
        <section className={styles['quiz-preview-card']}>
          {this.props.isMultiplayer ? <span className={`${styles['live-badge']} ${styles['pulse']}`}>live</span> : ''}
          <div className={styles['quiz-preview-img-container']} style={this.state.css}>
          </div>
          <div className={styles['quiz-preview-details']}>
            <p>&nbsp;</p>
            <h2 className={styles['quiz-name']}>{this.props.name}</h2>
            <div className={styles['quiz-preview-timer']}>
              <p></p>
              <span></span>
            </div>
          </div>
        </section>
      </Link>
    )
  }
}

export default GameCard
