import React from 'react'
import { Component } from 'react'
import styles from './Single.module.scss'


class Question extends Component {
  getMessage(percent) {
    if (percent <= 15) return 'You know nothing Jon Snow'
    else if (percent <= 30) return 'Too bad, I\'m sure you can do better.'
    else if (percent <= 60) return 'I guess that\'s ok..'
    else if (percent <= 85) return 'Very Nice!'
    else if (percent <= 95) return 'You are Amazing'
    else return 'Perfect!!! You deserve that citizenship.'
  }

  render() {
    const answers = this.props.answers
    console.log(answers)
    var correctAnswers = 0
    this.props.questions.forEach((question) => {
      if (question.correctOptIdx.includes(question.answered)) {
        correctAnswers++
      }
    })

    const percentage = parseInt((correctAnswers / this.props.questions.length) * 100)

    return (
      <div>
        <section className={styles['game-result']}>
          <div className={styles['game-summery']}>
            <div className={styles['score-label']}>
              <p>success rate</p>
              <span>{percentage}%</span>
            </div>
            <div className={styles['score-label']}>
              <p>right answers</p>
              <span>{correctAnswers}</span>
            </div>
          </div>

          <div className={styles['game-summery-msg']}>
            <h2>{this.getMessage(percentage)}</h2>
          </div>
          <div className={styles['continue']}>
            <button className={styles['white-btn']} onClick={this.props.goHome}>continue</button>
          </div>
        </section>
      </div>
    )
  }
}

export default Question

