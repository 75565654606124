import React from 'react'
import { Component } from 'react'
import ScoreItem from './ScoreItem'
import styles from './Scoreboard.module.scss'

class ScoreTable extends Component {
  constructor(props) {
    super(props)

    this.renderPlayers = this.renderPlayers.bind(this)
  }

  renderPlayers() {
    const players = []

    const sortedScore = Object.entries(this.props.players).sort((a, b) => b[1].score - a[1].score)
    let i = 1
    for (const [key, value] of sortedScore) {
      const isHighlighted = (key === this.props.userId) ? true : false
      players.push(<ScoreItem key={key} position={i} player={value} accumulateScores={this.props.accumulateScores} isHighlighted={isHighlighted}></ScoreItem>)
      i++
    }
    return players
  }

  render() {
    return (
      <div>
        <div className={styles['score-table']}>
          {this.renderPlayers()}
        </div>
      </div >
    )
  }
}

export default ScoreTable
