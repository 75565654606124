import React from 'react'
import { Component } from 'react'
import styles from './Question.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Timer from 'react-compound-timer'

class Question extends Component {
  constructor(props) {
    super(props)

    this.renderOptions = this.renderOptions.bind(this)
    this.renderTimer = this.renderTimer.bind(this)
    this.qTimer = React.createRef()

    this.state = {
      answerId: null,
      isAnswered: false,
      timeLeft: null,
      shake: false
    }
  }

  renderOptions() {
    const options = []
    for (const [i, value] of this.props.question.options.entries()) {
      const isCorrect = this.props.question.correctOptIdx.includes(i)
      const style = () => {
        if (this.state.answerId === null) return ''
        if (isCorrect) return styles['correct-option']
        return styles['wrong-option']
      }


      options.push(<button key={i} className={`${styles['option-btn']} ${style()}`} onClick={() => {
        if (this.state.answerId === null && !this.state.isAnswered) {
          this.renderTimer(i)
        }
      }}
      >
        {value}
        {this.state.answerId !== null ?
          <span className={`${styles['icon-span']} ${isCorrect ? styles['correct-icon'] : styles['wrong-icon']}`}>
            {isCorrect ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
          </span> : null
        }
      </button>)
    }
    return options
  }

  renderTimer(answerId) {
    if (!this.props.hideTimer) {
      const timeLeft = Math.round(this.qTimer.current.getTime() / 1000)
      if ((answerId !== null || timeLeft === 0) && !this.state.isAnswered) {
        this.setState({ isAnswered: true, timeLeft, answerId })
        this.props.answer(answerId, timeLeft)
      }

      if (timeLeft <= 0) {
        this.props.timeUp()
      }
    } else {
      this.setState({ isAnswered: true, timeLeft: 20, answerId })
      this.props.answer(answerId)
    }
  }

  render() {
    return (
      <div>
        <div className={styles['question-header']}>
          <span>Question {this.props.currentQuestion}/{this.props.totalQuestions}</span>
          {
            (!this.props.hideTimer) ? 
              <Timer
                ref={this.qTimer}
                direction="backward"
                initialTime={20000}
                checkpoints={[
                  {
                    time: 0,
                    callback: () => this.renderTimer(null),
                  }
                ]}
              >
                <h2 className={styles.timer}><Timer.Seconds /></h2>
              </Timer>
              : null
          }
          <h3>{this.props.question.text}</h3>
        </div>
        <div className={`${styles['question-body']} ${(this.state.shake) ? styles['shake'] : ''}`}>
          {this.renderOptions()}
        </div>
        {
          (this.props.hideTimer) ? 
            <div className={styles['next-question']}>
              <button 
                onClick={() => {
                  if (this.state.isAnswered) {
                    this.setState({ answerId: null, isAnswered: false })
                    this.props.nextQuestion()
                  } else {
                    this.setState({ shake: true })
                    setTimeout(
                      () => {
                        this.setState({ shake: false })
                      },
                      1000
                    )
                  }
                }}
              >NEXT QUESTION</button>
            </div>
            : null
        }
      </div>
    )
  }
}

export default Question

