const isWebPSupported = async () => {
  if (!window.createImageBitmap) return false

  const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA='
  const blob = await fetch(webpData).then(r => r.blob())
  return createImageBitmap(blob).then(() => true, () => false)
}

export const getBackgroundCSS = async (image) => {
  const isWebP = await isWebPSupported()
  const version = require('../../package.json').version
  if (isWebP) {
    return { backgroundImage: `url('/app/${image}.webp?v=${version}')`, backgroundPosition: 'center' }
  } else {
    return { backgroundImage: `url('/app/${image}.jpg?v=${version}')`, backgroundPosition: 'center' }
  }
}
