import React from 'react'
import { Component } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/Header/Header'
import styles from './NotFound.module.scss'
import { firebaseAnalytics } from '../../firebase'

class NotFound extends Component {
  componentDidMount() {
    console.log('404 MOUNTED')
    firebaseAnalytics.logEvent('page_view', {
      page_path: this.props.location.pathname
    })
  }

  componentWillUnmount() {
    console.log('404 UNMOUNTED')
  }

  render() {
    return (
      <div>
        <Header />
        <section className={styles['content-card']}>
          <div className={styles['boxed-content']}>
            <h2 className={styles.center}>Oops!</h2>
            <h3 className={styles.center}>We couldn't find what you're looking for</h3>
            <span></span>

            <Link to='/'>Go to Homepage</Link>
          </div>
        </section>
      </div >
    )
  }
}

export default NotFound

