export const USER_IS_LOADING = 'USER_IS_LOADING'
export const USER_IS_LOADED = 'USER_IS_LOADED'
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED'

export const userIsLoading = () => dispatch => {
  dispatch({
    type: USER_IS_LOADING
  })
}

export const userIsLoaded = () => dispatch => {
  dispatch({
    type: USER_IS_LOADED
  })
}

export const userAuth = (uid, name, photo) => dispatch => {
  dispatch({
    type: USER_AUTHENTICATED,
    payload: {
      uid,
      name,
      photo
    }
  })
}
