import firebase from 'firebase/app'
import 'firebase/auth'
import fetch from 'isomorphic-fetch'

export const getAvailableGame = (user, quiz) => {
  let url = '/findGame'
  if (process.env.NODE_ENV !== 'production') {
    url = 'https://us-central1-citizenship-pros-dev.cloudfunctions.net/findGame'
  }

  return firebase.auth().currentUser.getIdToken(true)
    .then((token) => {
      return fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user, token, quiz })
      }).then((response) => {
        return response.json()
      })
    })
}



export const sendAnswer = async (gameId, questionIdx, optionIdx, timeLeft) => {
  let url = '/answer'
  if (process.env.NODE_ENV !== 'production') {
    url = 'https://us-central1-citizenship-pros-dev.cloudfunctions.net/answer'
  }

  return firebase.auth().currentUser.getIdToken(true)
    .then(async (token) => {
      return fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ gameId, questionIdx, optionIdx, timeLeft, token })
      }).then((response) => {
        return response.json()
      })
    })
}

export const getQuestions = async (quiz, noOfQuestions) => {
  let url = '/getQuestions'
  if (process.env.NODE_ENV !== 'production') {
    url = 'https://us-central1-citizenship-pros-dev.cloudfunctions.net/getQuestions'
  }

  return fetch(`${url}?quiz=${encodeURIComponent(quiz)}&no=${encodeURIComponent(noOfQuestions)}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }).then((response) => {
    return response.json()
  })
}

export const getPlayers = async (gameId) => {
  let url = '/getPlayers'
  if (process.env.NODE_ENV !== 'production') {
    url = 'https://us-central1-citizenship-pros-dev.cloudfunctions.net/getPlayers'
  }

  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ gameId })
  }).then((response) => {
    return response.json()
  })
}

export const getQuizes = async (quiz, noOfQuestions) => {
  let url = '/getQuizes'
  if (process.env.NODE_ENV !== 'production') {
    url = 'https://us-central1-citizenship-pros-dev.cloudfunctions.net/getQuizes'
  }

  return fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }).then((response) => {
    return response.json()
  })
}
