import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import { firebaseauthui, firebaseuiConfig } from '../../firebase'
import * as userActions from '../../actions/user'
import * as gameActions from '../../actions/game'
import * as quizActions from '../../actions/quiz'
import * as singlePlayerActions from '../../actions/singlePlayer'
import { getGame } from '../../reducers/game'
import { getUser } from '../../reducers/user'
import { getQuizes } from '../../reducers/quiz'
import { firebaseAnalytics } from '../../firebase'
import styles from './Quiz.module.scss'
import { getBackgroundCSS } from '../../helpers/browserSupport'
import { SITE_URL } from '../../config'


class Quiz extends Component {
  constructor(props) {
    super(props)

    const { match: { params } } = this.props
    let quiz = this.props.quiz.get('quizes').filter(quiz => quiz.guid === params.id)
    if (quiz.length < 1) {
      quiz = null
    } else {
      quiz = quiz[0]
    }

    this.state = {
      showLogin: false,
      showSinglePlayerOptions: false,
      goToSinglePlay: false,
      goToLobby: false,
      quiz,
      css: null
    }

    this.joinGame = this.joinGame.bind(this)
    this.renderGameModes = this.renderGameModes.bind(this)
    this.singlePlayerOptions = this.singlePlayerOptions.bind(this)
    this.handleBackBtn = this.handleBackBtn.bind(this)
  }

  componentDidMount() {
    console.log('QUIZ MOUNTED')
    if (this.state.quiz) getBackgroundCSS(this.state.quiz.image).then((css) => {
      this.setState({ css })
    })

    firebaseAnalytics.logEvent('page_view', {
      page_path: this.props.location.pathname
    })

    if (this.props.location.hash === '#single') {
      this.setState({ showSinglePlayerOptions: true })
    }

    if (this.props.location.hash === '#login') {
      this.setState({ showLogin: true })
    }
  }

  componentDidUpdate() {
    if (!this.props.user.get('isLoading') && this.props.user.get('isLoaded') && this.props.user.get('isLoggedIn')) {
      if (this.state.showLogin) this.setState({ showLogin: false })
    }

    if (!this.props.user.get('isLoading') && this.props.user.get('isLoaded') && !this.props.user.get('isLoggedIn') && this.state.showLogin) {
      // user is logged out
      // set custom redirect uri
      firebaseuiConfig.signInSuccessUrl =  `${SITE_URL}/quiz/${this.state.quiz.guid}/lobby`
      firebaseauthui.start('#firebaseui-auth-container', firebaseuiConfig)
    }
  }

  componentWillUnmount() {
    console.log('QUIZ UNNNMOUNTED')
  }

  joinGame() {
    if (!this.props.user.get('isLoading') && this.props.user.get('isLoaded') && this.props.user.get('isLoggedIn')) {
      firebaseAnalytics.logEvent('select_content', {
        content_type: 'game',
        content_id: 'multiplayer'
      })
      this.setState({ goToLobby: true })
    } else {
      this.setState({ showLogin: true })
    }
  }

  startSingleGame(quiz, noOfQuestion) {
    firebaseAnalytics.logEvent('select_content', {
      content_type: 'game',
      content_id: 'single',
      questions: noOfQuestion
    })
    this.props.fetchQuestions(quiz, noOfQuestion)
    this.setState({ goToSinglePlay: true })
  }

  singlePlayerOptions(isMultiplayer) {
    return (
      <div className={(this.state.showLogin ? styles.hide : styles['boxed-content'])}>
        <h2 className={styles.center}>How many questions?</h2>
        <span></span>

        <button className={styles['ctpro-btn']} onClick={() => this.startSingleGame(this.state.quiz.guid, 10)}>
          10
        </button>
        <button className={styles['ctpro-btn']} onClick={() => this.startSingleGame(this.state.quiz.guid, 20)}>
          20
        </button>
        {
          (isMultiplayer) ? 
            <button className={styles['ctpro-btn']} onClick={() => this.startSingleGame(this.state.quiz.guid, 40)}>
              40
            </button>
            : 
            <button className={styles['ctpro-btn']} onClick={() => this.startSingleGame(this.state.quiz.guid, 100)}>
              ALL
            </button>
        }
        
      </div>
    )
  }

  handleBackBtn() {
    if (this.state.showSinglePlayerOptions) {
      this.setState({ showSinglePlayerOptions: false })
    } else if (this.state.showLogin) {
      this.setState({ showLogin: false })
    } else {
      this.props.history.push('/')
    }
  }

  renderGameModes(name) {
    return (
      <div className={(this.state.showLogin ? styles.hide : styles['boxed-content'])}>
        <h2 className={styles.center}>{name}</h2>
        <span></span>

        <button className={styles['ctpro-btn']} onClick={() => this.setState({ showSinglePlayerOptions: true })}>
          Single Player
        </button>
        <button className={styles['ctpro-btn']} onClick={() => this.joinGame()}>
          Multiplayer
        </button>
      </div>
    )
  }

  render() {
    if (!this.state.quiz) {
      return <Redirect to='/'></Redirect>
    }

    if (!this.state.goToLobby && !this.state.goToSinglePlay) {
      return (
        <section className={styles['quiz-card']}>
          <Helmet>
            <title>{this.state.quiz.name} | Canadian Citizenship Practice Test</title>
          </Helmet>
          <div className={styles['quiz-img']} style={this.state.css}></div>
          {(this.state.showSinglePlayerOptions || !this.state.quiz.isMultiplayer) ? this.singlePlayerOptions(this.state.quiz.isMultiplayer) : this.renderGameModes(this.state.quiz.name)}
          <div className={styles['back-btn']} onClick={() => this.handleBackBtn()}>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              // xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512.001 512.001"
              // style="enable-background:new 0 0 512.001 512.001;"
              // xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    d="M384.834,180.699c-0.698,0-348.733,0-348.733,0l73.326-82.187c4.755-5.33,4.289-13.505-1.041-18.26
            c-5.328-4.754-13.505-4.29-18.26,1.041l-82.582,92.56c-10.059,11.278-10.058,28.282,0.001,39.557l82.582,92.561
            c2.556,2.865,6.097,4.323,9.654,4.323c3.064,0,6.139-1.083,8.606-3.282c5.33-4.755,5.795-12.93,1.041-18.26l-73.326-82.188
            c0,0,348.034,0,348.733,0c55.858,0,101.3,45.444,101.3,101.3s-45.443,101.3-101.3,101.3h-61.58
            c-7.143,0-12.933,5.791-12.933,12.933c0,7.142,5.79,12.933,12.933,12.933h61.58c70.12,0,127.166-57.046,127.166-127.166
            C512,237.745,454.954,180.699,384.834,180.699z"
                  />
                </g>
              </g>
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
              <g />
            </svg>
          </div>
          <div className={(this.state.showLogin ? styles['boxed-content'] : styles.hide)}>
            <div id="firebaseui-auth-container"></div>
          </div>
        </section>
      )
    } else {
      if (this.state.showSinglePlayerOptions || !this.state.quiz.isMultiplayer) {
        return <Redirect to={`/quiz/${this.state.quiz.guid}/single`}></Redirect>
      } else {
        return <Redirect to={`/quiz/${this.state.quiz.guid}/lobby`}></Redirect>
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    game: getGame(state),
    user: getUser(state),
    quiz: getQuizes(state)
  }
}

export default connect(mapStateToProps, { ...userActions, ...gameActions, ...singlePlayerActions, ...quizActions })(Quiz)