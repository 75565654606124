import Immutable from 'immutable'
import { FIND_GAME, UPDATE_GAME, DESTROY_GAME } from '../actions/game'

const defaultState = Immutable.fromJS({
  isFinding: false,
  isFound: false,
  uid: null,
  inProgress: false,
  isCompleted: false,
  players: {},
  questions: []
})

export default (state = defaultState, action) => {
  switch (action.type) {
  case FIND_GAME:
    return state.withMutations((st) => {
      st.set('isFinding', true)
    })
  case UPDATE_GAME:
    return state.withMutations((st) => {
      st.set('isFinding', false)
      st.set('isFound', true)
      st.set('uid', action.payload.uid)
      st.set('inProgress', action.payload.inProgress)
      st.set('isCompleted', action.payload.isCompleted)
      st.set('players', action.payload.players)
      st.set('questions', action.payload.questions)
    })
  case DESTROY_GAME:
    return state.withMutations((st) => {
      st.set('isFinding', false)
      st.set('isFound', false)
      st.set('inProgress', false)
      st.set('isCompleted', true)
    })
  default:
    return state
  }
}

export const getGame = (state) => {
  return state.game
}
