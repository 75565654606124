export const SITE_URL = (process.env.NODE_ENV !== 'production') ? 'http://localhost:3000' : 'https://citizenshipquiz.ca'

const dev = {
  apiKey: 'AIzaSyDprEKIksTo_qGeETr126sU654Dl8CE2Oc',
  authDomain: 'citizenship-pros-dev.firebaseapp.com',
  databaseURL: 'https://citizenship-pros-dev.firebaseio.com',
  projectId: 'citizenship-pros-dev',
  storageBucket: 'citizenship-pros-dev.appspot.com',
  messagingSenderId: '249098140849',
  appId: '1:249098140849:web:e1629d6a18b31ad50e7690'
}
const production = {
  apiKey: 'AIzaSyBLzSCnMosoFF9V1dkdzW2W3ElotKdZYHM',
  authDomain: 'citizenshipquiz.ca',
  databaseURL: 'https://citizenship-pros.firebaseio.com',
  projectId: 'citizenship-pros',
  storageBucket: 'citizenship-pros.appspot.com',
  messagingSenderId: '1010888650590',
  appId: '1:1010888650590:web:ae1cb88e79ba90551c29cf',
  measurementId: 'G-59WQPML5Y0'
}
export const FIREBASE_CONFIG =  (process.env.NODE_ENV !== 'production') ?  dev : production