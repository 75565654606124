import Immutable from 'immutable'
import { 
  SINLGE_GAME_IS_LOADING, 
  SINLGE_GAME_IS_LOADED,
  SINLGE_GAME_ANSWER, 
  SINLGE_GAME_ENDED 
} from '../actions/singlePlayer'

const defaultState = Immutable.fromJS({
  isLoading: false,
  isLoaded: false,
  inProgress: false,
  questions: []
})

export default (state = defaultState, action) => {
  switch (action.type) {
  case SINLGE_GAME_IS_LOADING: 
    return state.withMutations((st) => {
      st.set('isLoading', true)
    })
  case SINLGE_GAME_IS_LOADED:
    return state.withMutations((st) => {
      st.set('isLoading', false)
      st.set('isLoaded', true)
      st.set('inProgress', true)
      st.set('questions', action.payload)
    })
  case SINLGE_GAME_ANSWER:
    return state.withMutations((st) => {
      st.setIn(['questions', action.payload.questionIdx, 'answered'], action.payload.answerId)
    })
  case SINLGE_GAME_ENDED:
    return state.withMutations((st) => {
      st.set('isLoading', false)
      st.set('isLoaded', false)
      st.set('inProgress', false)
    })
  default:
    return state
  }
}

export const getSinglePlayerGame = (state) => {
  return state.singlePlayer
}
