import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import * as userActions from '../../actions/user'
import * as gameActions from '../../actions/game'
import { getUser } from '../../reducers/user'
import { getGame } from '../../reducers/game'
import Question from '../../components/Question/Question'
import Scoreboard from '../../components/Scoreboard'
import Result from '../../components/Result/Result'
import { sendAnswer } from '../../services/gameService'
import { firebaseAnalytics } from '../../firebase'


class Game extends Component {
  constructor(props) {
    super(props)

    this.answer = this.answer.bind(this)
    this.timeUp = this.timeUp.bind(this)
    this.nextQuestion = this.nextQuestion.bind(this)
    this.goHome = this.goHome.bind(this)

    this.state = {
      answerId: undefined,
      totalQuestions: this.props.game.get('questions').length,
      currentQuestion: 0,
      timeLeft: null,
      timeUp: false,
      goToHome: false,
      showResult: false
    }
  }

  componentDidMount() {
    console.log('GAME MOUNTED')
    if (!this.props.user.get('isLoggedIn') || !this.props.game.get('inProgress')) {
      this.setState({ goToHome: true })
    }

    firebaseAnalytics.logEvent('page_view', {
      page_path: '/game/multiplayer',
      players: Object.values(this.props.game.get('players')).filter((player) => player && !player.isBot).length
    })
  }

  componentWillUnmount() {
    console.log('GAME UNNNMOUNTED')
  }

  goHome() {
    this.props.destroyGame()
    this.setState({ goToHome: true })
  }

  nextQuestion() {
    this.setState({ answerId: undefined, currentQuestion: this.state.currentQuestion + 1, timeLeft: null, timeUp: false })
  }

  answer(optionId, timeLeft) {
    if (this.state.answerId === undefined) {
      this.setState({ answerId: optionId, timeLeft })
      sendAnswer(this.props.game.get('uid'), this.state.currentQuestion, optionId, timeLeft)
    }
  }

  timeUp() {
    let showResult = false
    if (this.state.currentQuestion + 1 === this.state.totalQuestions) {
      showResult = true
    }
    this.setState({ timeUp: true, showResult })
  }

  render() {
    return (
      (!this.state.goToHome && this.props.game.get('uid')) ?
        <div>
          <Helmet>
            <title>Multiplayer Quiz | Canadian Citizenship Practice Test | Prepare for your citizenship test with other people</title>
          </Helmet>
          {(this.state.timeUp) ?
            (this.state.showResult) ?
              <Result
                userId={this.props.user.get('uid')}
                questions={this.props.game.get('questions')}
                players={this.props.game.get('players')}
                goHome={this.goHome}
              />
              :
              <Scoreboard
                userId={this.props.user.get('uid')}
                players={this.props.game.get('players')}
                scoreAdded={(this.props.game.get('questions')[this.state.currentQuestion].correctOptIdx.includes(this.state.answerId)) ? this.state.timeLeft : 0}
                currentQuestion={this.props.game.get('questions')[this.state.currentQuestion]}
                nextQuestion={this.nextQuestion}
              />
            :
            <Question
              question={this.props.game.get('questions')[this.state.currentQuestion]}
              totalQuestions={this.state.totalQuestions}
              currentQuestion={this.state.currentQuestion + 1}
              answer={this.answer}
              timeUp={this.timeUp}
            />

          }
        </div>
        :
        <Redirect to='/'></Redirect>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: getUser(state),
    game: getGame(state)
  }
}

export default connect(mapStateToProps, { ...userActions, ...gameActions })(Game)