import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Timer from 'react-compound-timer'
import { getPlayers } from '../../services/gameService'
import * as userActions from '../../actions/user'
import * as gameActions from '../../actions/game'
import { getUser } from '../../reducers/user'
import { getGame } from '../../reducers/game'
import { firebaseAnalytics } from '../../firebase'
import styles from './Lobby.module.scss'


class Lobby extends Component {
  constructor(props) {
    super(props)

    this.timer = React.createRef()
    this.renderLobby = this.renderLobby.bind(this)
    this.getLobbyStatus = this.getLobbyStatus.bind(this)
    this.getPlayers = this.getPlayers.bind(this)

    const { match: { params } } = this.props
    this.state = {
      quiz: params.id
    }
  }

  async componentDidMount() {
    console.log('LOBBY MOUNTED')
    firebaseAnalytics.logEvent('page_view', {
      page_path: this.props.location.pathname
    })
  }

  componentWillUnmount() {
    console.log('LOBBY UNNNMOUNTED')
  }

  componentDidUpdate() {
    if (
      !this.props.game.get('isFinding') 
      && this.props.game.get('isFound') 
      && this.timer.current
      && this.timer.current.state.state !== 'PLAYING') {
      this.timer.current.start()
    }
  }

  getLobbyStatus() {
    if (this.props.game.get('isFinding') && !this.props.game.get('isFound')) {
      return 'Finding game..'
    } else if (!this.props.game.get('isFinding') && this.props.game.get('isFound')) {
      return 'Waiting for other players..'
    } else {
      return 'Starting game..'
    }
  }

  getPlayers() {
    if (this.props.game.get('isFound')) {
      return Object.values(this.props.game.get('players')).sort((a, b) => {
        return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0
      }).map((player) => {
        return <div key={player.name} className={styles['player-name']}>{player.name}</div>
      })
    }
    return []
  }

  renderLobby() {
    // find game on load
    if (!this.props.game.get('isFinding') && !this.props.game.get('isFound')) {
      this.props.findGame(this.props.user, this.state.quiz)
    }

    // redirect to game when props change to in progress
    if (
      !this.props.game.get('isFinding') 
      && this.props.game.get('isFound')
      && this.props.game.get('inProgress')
    ) {
      return <Redirect to={`/game/${this.props.game.get('uid')}`}></Redirect>
    }

    // render lobby
    return (
      <section className={styles['lobby-card']}>
        <Helmet>
          <title>Game Lobby | Canadian Citizenship Practice Test | Prepare for your citizenship test with other people</title>
        </Helmet>
        <div className={styles['boxed-content']}>
          <h2 className={styles.center}>Game Lobby</h2>
          <span>{this.getLobbyStatus()}</span>

          <div className={styles.timer}>
            <h3 className={styles.heading}>
              <Timer
                ref={this.timer}
                startImmediately={false}
                direction="backward"
                initialTime={30000}
                checkpoints={[
                  {
                    time: 0,
                    callback: () => {
                      getPlayers(this.props.game.get('uid'))
                      this.setState({ isStarting: true })
                    },
                  },
                  // {
                  //   time: 6000,
                  //   callback: () => getPlayers(this.props.game.get('uid')),
                  // },
                  {
                    time: 12000,
                    callback: () => getPlayers(this.props.game.get('uid')),
                  },
                  {
                    time: 24000,
                    callback: () => getPlayers(this.props.game.get('uid')),
                  }
                ]}
              >
                <Timer.Seconds />
              </Timer>
            </h3>
          </div>

          <div className={styles.status}>
            <h3 className={styles['joined-headline']}>Joined:</h3>
            <div className={styles['join-list']}>
              {this.getPlayers()}
            </div>
          </div>
        </div>
      </section>
    )
  }

  render() {
    if (this.props.user.get('isLoading') && !this.props.user.get('isLoaded')) {
      // loading state
      return (<div className={styles['lds-ripple']}><div></div><div></div></div>)
    } else if (!this.props.user.get('isLoading') && this.props.user.get('isLoaded') && this.props.user.get('isLoggedIn')) {
      // logged in state
      return this.renderLobby()
    } else if (!this.props.user.get('isLoading') && this.props.user.get('isLoaded') && !this.props.user.get('isLoggedIn')) {
      // logged out state
      return <Redirect to={`/quiz/${this.state.quiz}#login`}></Redirect>
    } else {
      // temporary transition state
      return (<div className={styles['lds-ripple']}><div></div><div></div></div>)
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: getUser(state),
    game: getGame(state)
  }
}

export default connect(mapStateToProps, { ...userActions, ...gameActions })(Lobby)