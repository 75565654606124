import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/firebase-analytics'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import { SITE_URL, FIREBASE_CONFIG } from './config'

firebase.initializeApp(FIREBASE_CONFIG)
const databaseRef = firebase.firestore()
export const quizesRef = databaseRef.collection('quiz')
export const gameRef = databaseRef.collection('Game')
export const firebaseauthui = new firebaseui.auth.AuthUI(firebase.auth())
export const firebaseuiConfig = {
  signInSuccessUrl: `${SITE_URL}/quiz/Canada/lobby`,
  signInOptions: [
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  tosUrl: `${SITE_URL}/content/terms`,
  privacyPolicyUrl: `${SITE_URL}/content/privacy`,
}
export const firebaseAnalytics =  (process.env.NODE_ENV !== 'production') ? { 
  logEvent: (...args) => console.log(args),
  setUserId: () => true,
  setUserProperties: () => true
} : firebase.analytics()
