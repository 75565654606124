import Immutable from 'immutable'
import { USER_IS_LOADING, USER_IS_LOADED, USER_AUTHENTICATED } from '../actions/user'

const defaultState = Immutable.fromJS({
  isLoading: false,
  isLoaded: false,
  isLoggedIn: false,
  uid: null,
  name: null,
  photo: null,
  inLobby: false,
  inGame: false
})

export default (state = defaultState, action) => {
  switch (action.type) {
  case USER_IS_LOADING:
    return state.withMutations((st) => {
      st.set('isLoading', true)
    })
  case USER_IS_LOADED:
    return state.withMutations((st) => {
      st.set('isLoading', false)
      st.set('isLoaded', true)
    })
  case USER_AUTHENTICATED:
    return state.withMutations((st) => {
      st.set('isLoading', false)
      st.set('isLoaded', true)
      st.set('isLoggedIn', true)
      st.set('uid', action.payload.uid)
      st.set('name', action.payload.name)
      st.set('photo', action.payload.photo)
    })
  default:
    return state
  }
}

export const getUser = (state) => {
  return state.user
}
