import React, { forwardRef } from 'react'
import {Link} from 'react-router-dom'
import styles from './Header.module.scss'

const Header = forwardRef(() => {
  return (
    <header className={styles['app-header']}>
      <Link to="/">
        <h1>Citizenship Test</h1>
      </Link>
    </header>
  )
})

Header.displayName = 'Header'

export default Header
