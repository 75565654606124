import React from 'react'
import { Component } from 'react'
import styles from './Scoreboard.module.scss'
import ScoreTable from './ScoreTable'

class Scoreboard extends Component {
  constructor(props) {
    super(props)

    this.getCorrectAnswer = this.getCorrectAnswer.bind(this)
  }

  getCorrectAnswer() {
    const correctAnswer = this.props.currentQuestion.options
      .filter((option, i) => {
        if (this.props.currentQuestion.correctOptIdx.includes(i)) return true
        return false
      })
    return correctAnswer
  }

  render() {
    return (
      <div>
        <section>
          <div className={styles['score-label']}>
            <p>Correct Answer</p>
            <span className={styles.center}>{this.getCorrectAnswer()}</span>
          </div>
          <ScoreTable players={this.props.players} accumulateScores={false} userId={this.props.userId}></ScoreTable>
          <div className={styles['table-heading']}></div>
          <div className={styles['next-question']}>
            <button onClick={this.props.nextQuestion}>next question</button>
          </div>
        </section>
      </div >
    )
  }
}

export default Scoreboard
