import Immutable from 'immutable'
import { QUIZES_LOADING, QUIZES_LOADED } from '../actions/quiz'

const defaultState = Immutable.fromJS({
  isLoading: false,
  isLoaded: false,
  quizes: []
})

export default (state = defaultState, action) => {
  switch (action.type) {
  case QUIZES_LOADING:
    return state.withMutations((st) => {
      st.set('isLoading', true)
    })
  case QUIZES_LOADED:
    return state.withMutations((st) => {
      st.set('isLoading', false)
      st.set('isLoaded', true)
      st.set('quizes', action.payload)
    })
  default:
    return state
  }
}

export const getQuizes = (state) => {
  return state.quiz
}
