import React from 'react'
import { Component } from 'react'
import ScoreTable from '../Scoreboard/ScoreTable'
import styles from './Result.module.scss'


class Question extends Component {
  getMessage(percent) {
    if (percent <= 15) return 'You know nothing, Jon Snow.'
    else if (percent <= 30) return 'Too bad, I\'m sure you can do better.'
    else if (percent <= 60) return 'I guess that\'s ok..'
    else if (percent <= 85) return 'Very Nice!'
    else if (percent <= 95) return 'You are Amazing.'
    else return 'Perfect!!! You deserve that citizenship.'
  }

  render() {
    const winners = Object.keys(this.props.players)
      .map((uid) => {
        return {
          name: this.props.players[uid].name,
          score: this.props.players[uid].score
        }
      })
      .sort((a, b) => b.score - a.score)

    const player = this.props.players[this.props.userId]
    var correctAnswers = 0
    for (const [i, value] of this.props.questions.entries()) {
      if (value.correctOptIdx.includes(player.answers[i])) {
        correctAnswers++
      }
    }

    const percentage = parseInt((correctAnswers / this.props.questions.length) * 100)

    return (
      <div>
        <section className={styles['game-result']}>
          <div className={styles['winners-podium-trophy']}>
            <svg
              width="107px"
              height="127px"
              viewBox="0 0 107 127"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="trophy" fillRule="nonzero">
                  <rect id="Rectangle" fill="#FECF05" x="49" y="78" width="10" height="10" />
                  <g id="Group" transform="translate(0.000000, 10.000000)" fill="#F8B816">
                    <path
                      d="M28.1081535,43.87 C28.0713256,43.87 28.0337512,43.86825 27.9964256,43.865 C20.6644372,43.2215 13.7923,38.78875 8.6456,31.38325 C3.1390814,23.462 0.106751163,12.95925 0.106751163,1.80975 C0.106751163,0.89825 0.845051163,0.15725 1.75231163,0.15725 L17.3434558,0.15725 C18.0105884,0.15725 18.5662419,0.67075 18.6214837,1.33825 C18.7817349,3.272 18.9818,5.21175 19.2167023,7.103 C19.2619907,7.4695 19.1485209,7.83775 18.9051581,8.1145 C18.6615465,8.39125 18.3116814,8.54975 17.9439,8.54975 L10.5111326,8.54975 C10.1020442,8.54975 9.71535116,8.7295 9.45033953,9.043 C9.18657209,9.35575 9.07285349,9.76875 9.1397907,10.1735 C10.2802116,17.07425 12.6578512,23.635 15.4993233,27.72275 C18.6869279,32.30975 22.1614419,35.36775 25.5483651,36.56625 C25.8765814,36.6825 26.1435837,36.92725 26.288407,37.245 C26.7283512,38.21025 27.1814837,38.8095 27.6614907,39.44425 C28.0986977,40.0225 28.5508349,40.62075 29.016907,41.54 C29.0933,41.6905 29.1699419,41.84025 29.2473302,41.99 C29.4620767,42.407 29.434207,42.9085 29.1739233,43.2985 C28.934293,43.6575 28.5334163,43.87 28.1081535,43.87 Z"
                      id="Path"
                    />
                    <path
                      d="M78.8913488,43.87 C78.466086,43.87 78.0652093,43.6575 77.8258279,43.29875 C77.565793,42.90825 77.5379233,42.40675 77.7529186,41.98975 C77.830307,41.84025 77.9069488,41.6905 77.9833419,41.54 C78.4486674,40.6215 78.9013023,40.02325 79.3387581,39.44425 C79.8187651,38.80975 80.2716488,38.2105 80.711593,37.24525 C80.8564163,36.9275 81.1231698,36.68275 81.4516349,36.5665 C84.8375628,35.368 88.3128233,32.31 91.5006767,27.723 C94.3423977,23.635 96.7197884,17.07475 97.8604581,10.17425 C97.9271465,9.769 97.8136767,9.3565 97.5496605,9.043 C97.2848977,8.73025 96.8982047,8.55 96.4891163,8.55 L89.0561,8.55 C88.6880698,8.55 88.3382047,8.3915 88.0948419,8.11475 C87.8517279,7.838 87.7380093,7.46975 87.7832977,7.10325 C88.0179512,5.21225 88.2182651,3.2725 88.3787651,1.3385 C88.4337581,0.671 88.9894116,0.1575 89.6565442,0.1575 L105.247688,0.1575 C106.154949,0.1575 106.893249,0.89875 106.893249,1.81 C106.893249,12.96 103.86067,23.4625 98.3546488,31.38325 C93.2079488,38.78875 86.3355628,43.2215 79.0035744,43.865 C78.9657512,43.86825 78.9284256,43.87 78.8913488,43.87 Z"
                      id="Path"
                    />
                  </g>
                  <path
                    d="M89.4378636,0.594296405 C89.0670716,0.214116007 88.559959,0 88.0300437,0 L17.9701842,0 C17.4407646,0 16.933652,0.214116007 16.56286,0.594296405 C16.1915723,0.974725775 15.9888264,1.48835522 16,2.02015963 C16.3980361,20.8748168 20.2911042,38.5040333 26.9631295,51.661213 C31.4136249,60.4367326 36.8315008,66.6692512 42.7614464,70 L63.2387816,70 C69.1684793,66.6692512 74.5863552,60.4367326 79.0368506,51.661213 C85.7091237,38.5040333 89.6024397,20.8748168 90,2.02015963 C90.0114015,1.48810625 89.8086556,0.974725775 89.4378636,0.594296405 Z"
                    id="Path"
                    fill="#FECF05"
                  />
                  <path
                    d="M32.2259348,51.661213 C25.5639041,38.5040333 21.6768121,20.8748168 21.2801095,2.02015963 C21.2684782,1.48835522 21.4709128,0.974725775 21.841878,0.594296405 C22.2118534,0.214116007 22.7181875,0 23.2467944,0 L17.9671598,0 C17.4385529,0 16.9322188,0.214116007 16.561996,0.594296405 C16.1912782,0.974725775 15.9888436,1.48835522 16.0004749,2.02015963 C16.397425,20.8748168 20.284517,38.5040333 26.9463002,51.661213 C31.3899638,60.4367326 36.7995228,66.6692512 42.7203654,70 L48,70 C42.0794048,66.6692512 36.6695983,60.4367326 32.2259348,51.661213 Z"
                    id="Path"
                    fill="#000000"
                    opacity="0.1"
                  />
                  <path
                    d="M66,96 L66,88.7619305 C66,87.7888262 65.1909466,87 64.1924502,87 L42.8075498,87 C41.8090534,87 41,87.7888262 41,88.7619305 L41,96 L66,96 Z"
                    id="Path"
                    fill="#F8B816"
                  />
                  <path
                    d="M48,87 L42.8427598,87 C41.8248133,87 41,87.7888262 41,88.7619305 L41,96 L46.157494,96 L46.157494,88.7619305 C46.157494,87.7888262 46.9823073,87 48,87 Z"
                    id="Path"
                    fill="#000000"
                    opacity="0.1"
                  />
                  <path
                    d="M74,121 L74,97.2855645 C74,96.5785165 73.4251746,96 72.7224449,96 L34.2773072,96 C33.5745776,96 33,96.5785165 33,97.2855645 L33,121 L74,121 Z"
                    id="Path"
                    fill="#A67C52"
                  />
                  <path
                    d="M66,113.238456 C66,113.657182 65.6550856,114 65.2333031,114 L40.7666969,114 C40.3449144,114 40,113.657182 40,113.238456 L40,103.761544 C40,103.342572 40.3449144,103 40.7666969,103 L65.2333031,103 C65.6550856,103 66,103.342818 66,103.761544 L66,113.238456 Z"
                    id="Path"
                    fill="#FECF05"
                  />
                  <path
                    d="M47.2463788,113.238226 L47.2463788,103.761527 C47.2463788,103.342564 47.5854109,103 48,103 L41.7536212,103 C41.339032,103 41,103.34281 41,103.761527 L41,113.238473 C41,113.65719 41.339032,114 41.7536212,114 L48,114 C47.5854109,113.999754 47.2463788,113.656943 47.2463788,113.238226 Z"
                    id="Path"
                    fill="#000000"
                    opacity="0.1"
                  />
                  <path
                    d="M82,125.748759 C82,126.43693 81.4265016,127 80.7250918,127 L26.2749082,127 C25.5737458,127 25,126.43693 25,125.748759 L25,121.251241 C25,120.56307 25.5737458,120 26.2749082,120 L80.7250918,120 C81.4265016,120 82,120.56307 82,121.251241 L82,125.748759 Z"
                    id="Path"
                    fill="#8C6239"
                  />
                  <path
                    d="M31.6560167,125.748759 L31.6560167,121.251241 C31.6560167,120.56307 32.2608483,120 33,120 L26.3439833,120 C25.6048316,120 25,120.56307 25,121.251241 L25,125.748759 C25,126.43693 25.6048316,127 26.3439833,127 L33,127 C32.2608483,127 31.6560167,126.43693 31.6560167,125.748759 Z"
                    id="Path"
                    fill="#000000"
                    opacity="0.1"
                  />
                  <path
                    d="M42,69 L42,76.636223 C42,77.3861416 42.6390372,78 43.4199131,78 L63.5803411,78 C64.3609628,78 65,77.3863858 65,76.636223 L65,69 L42,69 Z"
                    id="Path"
                    fill="#F8B816"
                  />
                  <path
                    d="M47.622057,76.636223 L47.622057,69 L42,69 L42,76.636223 C42,77.3861416 42.6202594,78 43.3781898,78 L49,78 C48.2420696,77.9997557 47.622057,77.3861416 47.622057,76.636223 Z"
                    id="Path"
                    fill="#000000"
                    opacity="0.1"
                  />
                  <circle id="Oval" fill="#F8B816" cx="53" cy="32" r="19" />
                  <path
                    d="M58.4395501,40 C58.2611191,40 58.0816925,39.9685113 57.9097318,39.9050418 L53.5002192,38.268857 L49.0907067,39.9047957 C48.9189948,39.9682652 48.7393194,39.999754 48.5608883,39.999754 C48.2470786,39.999754 47.9362551,39.9028277 47.6742078,39.7153713 C47.2643389,39.4211483 47.0319056,38.943159 47.0543028,38.4432752 L47.2660809,33.7942571 L44.3290603,30.155585 C44.0130109,29.7641898 43.9172006,29.2424114 44.073981,28.7663901 C44.2302638,28.2903689 44.618482,27.9240662 45.1067412,27.790731 L49.6466552,26.5533218 L52.2412473,22.6691358 C52.5207147,22.251418 52.9930469,22 53.4999704,22 C54.0063961,22 54.4792261,22.251418 54.7579469,22.6688898 L57.3530367,26.5533218 L61.8931996,27.790731 C62.3814587,27.9240662 62.769677,28.2903689 62.9259597,28.7663901 C63.082989,29.2424114 62.9866811,29.7641898 62.6708804,30.155585 L59.7341088,33.7942571 L59.9456379,38.4432752 C59.9680352,38.943651 59.7356019,39.4213943 59.325733,39.7153713 C59.0639345,39.9030737 58.7531111,40 58.4395501,40 Z"
                    id="Path"
                    fill="#FFFFFF"
                  />
                  <g id="Group" transform="translate(35.000000, 28.000000)" fill="#A67C52">
                    <path
                      d="M4.4381039,0.176210526 L0.64525974,0.176210526 C0.382467532,1.32347368 0.234415584,2.51123684 0.210974026,3.72884211 L4.4381039,3.72884211 C5.4604026,3.72884211 6.28875325,2.93352632 6.28875325,1.95252632 C6.28875325,0.971526316 5.4604026,0.176210526 4.4381039,0.176210526 Z"
                      id="Path"
                    />
                    <path
                      d="M4.4381039,5.33415789 L0.253662338,5.33415789 C0.342246753,6.55602632 0.557168831,7.74402632 0.887077922,8.88678947 L4.4381039,8.88678947 C5.4604026,8.88678947 6.28875325,8.09147368 6.28875325,7.11047368 C6.28875325,6.12947368 5.4604026,5.33415789 4.4381039,5.33415789 Z"
                      id="Path"
                    />
                    <path
                      d="M37.7885325,3.72884211 C37.7650909,2.51123684 37.6172857,1.32347368 37.3542468,0.176210526 L33.3375974,0.176210526 C32.3152987,0.176210526 31.4869481,0.971526316 31.4869481,1.95252632 C31.4869481,2.93352632 32.3152987,3.72884211 33.3375974,3.72884211 L37.7885325,3.72884211 Z"
                      id="Path"
                    />
                    <path
                      d="M33.3373506,5.33415789 C32.3150519,5.33415789 31.4867013,6.12947368 31.4867013,7.11047368 C31.4867013,8.09147368 32.3150519,8.88678947 33.3373506,8.88678947 L37.1124286,8.88678947 C37.4420909,7.74402632 37.657013,6.55602632 37.7455974,5.33415789 L33.3373506,5.33415789 Z"
                      id="Path"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div className={styles['winners-podium']}>
            {(winners[2]) ?
              <div className={`${styles['place-3rd']} ${styles.place}`}>
                <span>3</span>
                <div className={styles['player-name']}>{winners[2].name}</div>
                <div className={styles['player-score']}>{winners[2].score}</div>
              </div> : null
            }
            <div className={`${styles['place-1st']} ${styles.place}`}>
              <span>1</span>
              <div className={styles['player-name']}>{winners[0].name}</div>
              <div className={styles['player-score']}>{winners[0].score}</div>
            </div>
            {(winners[1]) ?
              <div className={`${styles['place-2nd']} ${styles.place}`}>
                <span>2</span>
                <div className={styles['player-name']}>{winners[1].name}</div>
                <div className={styles['player-score']}>{winners[1].score}</div>
              </div> : null
            }
          </div>
          <div className={styles['game-summery']}>
            <div className={styles['score-label']}>
              <p>success rate</p>
              <span>{percentage}%</span>
            </div>
            <div className={styles['score-label']}>
              <p>right answers</p>
              <span>{correctAnswers}</span>
            </div>
          </div>

          <div className={styles['game-summery-msg']}>
            <h2>{this.getMessage(percentage)}</h2>
          </div>
          <ScoreTable players={this.props.players} accumulateScores={true} userId={this.props.userId}></ScoreTable>
          <div className={styles['continue']}>
            <button className={styles['white-btn']} onClick={this.props.goHome}>continue</button>
          </div>
        </section>
      </div>
    )
  }
}

export default Question

