import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import Header from '../../components/Header/Header'
import GameCard from './GameCard'
import styles from './Homepage.module.scss'
import * as gameActions from '../../actions/game'
import { getGame } from '../../reducers/game'
import { getQuizes } from '../../reducers/quiz'
import { firebaseAnalytics } from '../../firebase'

class Homepage extends Component {
  constructor(props) {
    super(props)

    this.loadTopics = this.loadTopics.bind(this)
    this.loadMultiplayer = this.loadMultiplayer.bind(this)
  }
  componentDidMount() {
    console.log('HOME MOUNTED')
    firebaseAnalytics.logEvent('page_view', {
      page_path: this.props.location.pathname
    })
  }

  componentWillUnmount() {
    console.log('HOME UNNNMOUNTED')
  }

  loadMultiplayer() {
    return this.props.quiz.get('quizes').filter(quiz => quiz.isMultiplayer).map((quiz) => {
      return <GameCard 
        key={quiz.guid} 
        guid={quiz.guid} 
        name={quiz.name} 
        isMultiplayer={quiz.isMultiplayer}
        image={quiz.image}
      >
      </GameCard>
    })
  }

  loadTopics() {
    return this.props.quiz.get('quizes').filter(quiz => !quiz.isMultiplayer).map((quiz) => {
      return <GameCard 
        key={quiz.guid} 
        guid={quiz.guid} 
        name={quiz.name}
        isMultiplayer={quiz.isMultiplayer}
        image={quiz.image}
      ></GameCard>
    })
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Canadian Citizenship Practice Test | Prepare for your citizenship test with other people</title>
        </Helmet>
        <Header />
        <section className={styles['homepage']}>
          <h1 className={`${styles['white-headline']} ${styles['margin-fix-bottom']}`}>Play with others</h1>
          <section className={styles['quiz-list-container']}>
            {this.loadMultiplayer()}
          </section>
          <h1 className={`${styles['white-headline']} ${styles['margin-fix']} ${styles['margin-fix-bottom']} ${styles['margin-fix-top']}`}>Learn by Topic</h1>
          <section className={`${styles['quiz-list-container']} ${styles['margin-fix-bottom']}`}>
            {this.loadTopics()}
          </section>
        </section>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    game: getGame(state),
    quiz: getQuizes(state)
  }
}

export default connect(mapStateToProps, { ...gameActions })(Homepage)
